/* eslint-disable no-nested-ternary */
/* eslint-disable default-case */
import React from 'react';
import { Button } from '@swagup-com/components';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import Confetti from 'react-canvas-confetti';
import InfoIcon from '@material-ui/icons/Info';
import WarningIcon from '@material-ui/icons/Warning';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { Img } from '../../global/ImgUtils';
import { Modal } from '../../shared/Modal';
import { StylessButton } from '../../buttons';
import { ZoomIconSearch } from './ProofDetailsSectionsExtension';

const useStyles = makeStyles({
  dialog: {
    width: 800,
    maxWidth: 800,
    borderRadius: 10,
    '& .MuiDialogContent-root': {
      padding: '0px 96px 32px '
    }
  },
  commonDialog: {
    width: 600,
    maxWidth: 600,
    borderRadius: 10,
    '& .MuiDialogContent-root': {
      padding: '0px 0px 32px 0px'
    }
  },
  title: {
    fontFamily: 'Inter',
    fontSize: 20,
    color: '#0B1829',
    textAlign: 'center',
    marginTop: 20,
    marginBottom: 20,
    fontWeight: 'bold'
  },
  subtitle: {
    fontFamily: 'Inter',
    fontSize: 14,
    fontWeight: 'normal',
    color: '#131415',
    textAlign: 'center',
    marginTop: 10,
    lineHeight: 1.5
  },
  cancelButton: {
    fontFamily: 'inter',
    fontSize: 14,
    fontWeight: 'normal',
    color: '#125CFF',
    textAlign: 'center'
  },
  deleteButton: {
    height: 40,
    background: '#C62828',
    fontFamily: 'inter',
    fontSize: 14,
    fontWeight: 'normal'
  },
  button: {
    height: 40,
    background: '#125CFF',
    fontFamily: 'inter',
    fontSize: 14,
    fontWeight: 'normal'
  },
  imageContainer: {
    height: 124,
    width: 150,
    maxWidth: '100%',
    maxHeight: '100%',
    textAlign: 'center',
    position: 'relative',
    padding: 5,
    border: 'solid 1px #ebedf0',
    borderRadius: 5,
    backgroundColor: '#ffffff',
    marginBottom: 17,
    '& img': {
      objectFit: 'contain',
      height: '100%'
    }
  },
  productTextStyle: {
    fontFamily: 'inter',
    fontSize: 18,
    color: '#0B1829',
    fontWeight: 'bold'
  },
  productDetails: {
    color: '#0B1829',
    fontFamily: 'inter',
    fontSize: 14,
    lineHeight: 1,
    padding: '4px 0px'
  },
  productDetailsValue: {
    fontWeight: 'bold',
    padding: '0px 8px'
  },
  note: {
    color: '#0B1829',
    fontFamily: 'inter',
    fontSize: 12,
    lineHeight: 1.5,
    padding: '6px 0px'
  },
  warningNote: {
    color: '#EF6C00',
    fontFamily: 'inter',
    fontSize: 12,
    lineHeight: 1.5,
    padding: '6px 0px',
    marginLeft: '4px'
  },
  decorationListheader: {
    fontFamily: 'inter',
    fontSize: 14,
    color: '#131415',
    borderTop: '1px solid #E5E7E8',
    borderBottom: '1px Solid #E5E7E8',
    padding: '8px 0px',
    marginBottom: 8,
    fontWeight: 'bold'
  },
  decorationListItems: {
    fontFamily: 'inter',
    fontSize: 12,
    color: '#131415'
  }
});

const ProofDetailsModal = ({
  open,
  onToggleOpen,
  type,
  tabCount,
  setTabCount,
  selectedStatusIndex,
  selected,
  setOpenDialog,
  hideIconSearch,
  fireConfetti,
  handleFinishedAnimation,
  recentDecorations,
  requestDelete,
  refetch,
  isPack,
  approvalCount,
  productColor,
  numberOfApprovedItems = 0,
  areAllItemsApproved = false,
  totalProducts,
  handlePlaceNewOrder,
  selectNextItem
}) => {
  const classes = useStyles();
  function convertObjectToArray(obj) {
    if (obj) return Object.values(obj);
    return [];
  }

  recentDecorations = convertObjectToArray(recentDecorations);

  const renderContent = () => {
    switch (type) {
      case 'design-request':
        return (
          <Grid container direction="row" alignItems="center" justifyContent="center" style={{ padding: 0 }}>
            <Grid item xs={12} style={{ padding: '8px 0px 0px 0px' }}>
              <p className={classes.title}>Your mockups will now being designed as per requested</p>
            </Grid>
            <Grid md={10} style={{ display: 'flex', justifyContent: 'center', textAlign: 'center' }}>
              <Typography className={classes.productDetails} style={{ width: '70%' }}>
                We are working to design your mockups.
              </Typography>
            </Grid>
            <Grid md={10} style={{ display: 'flex', justifyContent: 'center', textAlign: 'center' }}>
              <Typography className={classes.productDetails} style={{ width: '100%', marginBottom: '10px' }}>
                You will receive an email shortly when your mockups are ready to review.
              </Typography>
            </Grid>
            <Grid xs={12} item justifyContent="center">
              <Grid container style={{ display: 'flex', flexDirection: 'column' }}>
                <div style={{ maxHeight: '30rem', overflowY: 'hidden', overflow: 'scroll' }}>
                  <Grid style={{ display: 'flex', padding: '16px 0px' }}>
                    <div className={classes.imageContainer}>
                      <StylessButton
                        onClick={() => setOpenDialog(true)}
                        disabled={hideIconSearch}
                        width="100%"
                        style={{ cursor: selected?.image === '/images/proofs/mockup.png' ? 'arrow' : 'zoom-in' }}
                      >
                        <div>
                          <Img src={selected?.image} alt="product" style={{ width: '100%', objectFit: 'contain' }} />
                          {!hideIconSearch && <ZoomIconSearch classes={classes} />}
                          <Confetti
                            fire={fireConfetti}
                            particleCount={250}
                            origin={{ x: 0.5, y: 1 }}
                            colors={['#3577d4', '#9846dd', '#ffffff', '#0066ff', '#F0E8F7', '#F7A9FA']}
                            style={{ position: 'absolute', left: 0, top: 0, width: '100%', height: '100%' }}
                            onDecay={handleFinishedAnimation}
                          />
                        </div>
                      </StylessButton>
                    </div>
                    <Grid style={{ padding: '0px 0px 0px 16px' }}>
                      <Grid>
                        <Typography className={classes.productTextStyle}>{selected?.name}</Typography>
                      </Grid>
                      <Typography className={classes.productDetails}>
                        Product color:
                        <span className={classes.productDetailsValue}>
                          {selected?.theme_color || productColor?.name}
                        </span>
                      </Typography>
                      <Typography className={classes.productDetails}>
                        Decoration requests:
                        <span className={classes.productDetailsValue}>{recentDecorations?.length || 0}</span>
                      </Typography>

                      <Typography className={classes.warningNote}>
                        <WarningIcon style={{ color: '#EF6C00', fontSize: 12, marginRight: 8 }} />
                        Changes may be reflected on the unit price of this item
                      </Typography>
                    </Grid>
                  </Grid>
                  {recentDecorations?.map((item, index) => {
                    return (
                      <Grid container key={index} style={{ marginBottom: 12 }}>
                        <Grid item md={12}>
                          <Typography className={classes.decorationListheader}>
                            Decoration Request - {item?.Name === undefined ? index + 1 : item.Name}
                          </Typography>
                        </Grid>
                        <Grid item md={12} container style={{ display: 'flex', flexWrap: 'wrap', marginBottom: 12 }}>
                          {item?.Imprint_Type ? (
                            <>
                              <Grid item md={4} style={{ display: 'flex', alignItems: 'center' }}>
                                <Typography className={classes.decorationListItems}>Related files: 1</Typography>
                              </Grid>
                              <Grid item md={8}>
                                <Typography className={classes.decorationListItems}>
                                  Method:{' '}
                                  <span style={{ marginLeft: 8, fontWeight: 'bold' }}>
                                    {item?.Imprint_Type || 'N/A'}
                                  </span>
                                </Typography>
                              </Grid>
                              <Grid item md={4} style={{ display: 'flex', alignItems: 'center' }}>
                                {item?.Artwork1_S3_Location ? (
                                  <img
                                    src={item?.Artwork1_S3_Location}
                                    alt="Artwork"
                                    style={{ width: 24, height: 24, marginRight: 8 }} // Removed marginLeft as it's not necessary
                                  />
                                ) : (
                                  <img
                                    src="/images/public/moontest.svg"
                                    alt="Artwork"
                                    style={{ width: 24, height: 24, marginRight: 8 }} // Removed marginLeft as it's not necessary
                                  />
                                )}
                                <span
                                  style={{
                                    maxWidth: '150px',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    display: 'inline-block',
                                    verticalAlign: 'middle'
                                  }}
                                  title={item?.Artwork1}
                                >
                                  {item?.Artwork1 || 'Moontest.svg'}
                                </span>
                                <img
                                  src="/images/public/open-eye.svg"
                                  alt="View"
                                  style={{ marginLeft: 8, width: 16, height: 16 }}
                                />
                              </Grid>
                              <Grid item md={8}>
                                <Typography className={classes.decorationListItems}>
                                  Color: <span style={{ marginLeft: 8, fontWeight: 'bold' }}>{item?.Colors}</span>
                                </Typography>
                              </Grid>
                              <Grid item md={4} />
                            </>
                          ) : (
                            <>
                              <Grid item md={4} style={{ display: 'flex', alignItems: 'center' }}>
                                <Typography className={classes.decorationListItems}>Related files: 1</Typography>
                              </Grid>
                              <Grid item md={8}>
                                <Typography className={classes.decorationListItems}>
                                  Color: <span style={{ marginLeft: 8, fontWeight: 'bold' }}>{item?.Colors}</span>
                                </Typography>
                              </Grid>
                              <Grid item md={4} style={{ display: 'flex', alignItems: 'center' }}>
                                {item?.Artwork1_S3_Location ? (
                                  <img
                                    src={item?.Artwork1_S3_Location}
                                    alt="Artwork"
                                    style={{ width: 24, height: 24, marginRight: 8 }} // Removed marginLeft as it's not necessary
                                  />
                                ) : (
                                  <img
                                    src="/images/public/moontest.svg"
                                    alt="Artwork"
                                    style={{ width: 24, height: 24, marginRight: 8 }} // Removed marginLeft as it's not necessary
                                  />
                                )}
                                <span
                                  style={{
                                    maxWidth: '150px',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    display: 'inline-block',
                                    verticalAlign: 'middle'
                                  }}
                                  title={item?.Artwork1}
                                >
                                  {item?.Artwork1 || 'Moontest.svg'}
                                </span>
                                <img
                                  src="/images/public/open-eye.svg"
                                  alt="View"
                                  style={{ marginLeft: 8, width: 16, height: 16 }}
                                />
                              </Grid>
                            </>
                          )}
                          <Grid item md={8}>
                            <Typography className={classes.decorationListItems}>
                              Location: <span style={{ marginLeft: 8, fontWeight: 'bold' }}>{item?.Location}</span>
                            </Typography>
                          </Grid>
                          <Grid item md={4} />
                          <Grid item md={8}>
                            <Typography className={classes.decorationListItems}>
                              Dimension: <span style={{ marginLeft: 8, fontWeight: 'bold' }}>{item?.Dimensions}</span>
                            </Typography>
                          </Grid>
                          <Grid item md={4} />
                          <Grid item md={8}>
                            <Typography className={classes.decorationListItems}>
                              Notes: <span style={{ marginLeft: 8, fontWeight: 'bold' }}>{item?.Notes}</span>
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    );
                  })}
                </div>
                <Grid
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    textAlign: 'center',
                    padding: '0px 8px',
                    marginTop: '10px'
                  }}
                >
                  <InfoIcon />
                  <Typography className={classes.note}>
                    Any decorations that have been added or deleted will not be immediately reflected. One of our
                    designers will make these revisions and send them back for your approval.
                  </Typography>
                </Grid>
                <Grid style={{ justifyContent: 'center', display: 'inline-flex', paddingTop: 16 }}>
                  <Button
                    variant="contained"
                    color="#C62828"
                    className={classes.button}
                    onClick={() => {
                      onToggleOpen();
                      setTabCount(tabCount - 1);
                    }}
                  >
                    Great!
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        );
      case 'mockup-approval':
        return (
          <Grid container direction="row" alignItems="center" justifyContent="center" style={{ padding: 0 }}>
            <Grid
              item
              xs={8}
              style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}
            >
              <img src="/images/public/mockup-approval.svg" alt="mockup-approval" className={classes.logo} />
              <p className={classes.title}>Your mockups are locked in!</p>
            </Grid>
            <Grid
              xs={12}
              style={{ display: 'flex', justifyContent: 'center', textAlign: 'center', padding: '0px 8px' }}
            >
              <Typography className={classes.note}>We will send you the final proof for approval shortly</Typography>
            </Grid>
            <Grid style={{ justifyContent: 'center', display: 'inline-flex', paddingTop: 16 }}>
              <Button
                variant="contained"
                color="#C62828"
                className={classes.button}
                onClick={() => {
                  onToggleOpen();
                  setTabCount(tabCount - 1);
                }}
              >
                Great!
              </Button>
            </Grid>
          </Grid>
        );
      case 'proof-review':
        return (
          <Grid container direction="row" alignItems="center" justifyContent="center" style={{ padding: 0 }}>
            <Grid
              item
              xs={8}
              style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}
            >
              <img src="/images/public/mockup-approval.svg" alt="mockup-approval" className={classes.logo} />
              {!isPack ? (
                <>
                  <p className={classes.title}>This item is now ready to be ordered</p>
                  <Grid style={{ justifyContent: 'center', display: 'inline-flex', paddingTop: 16 }}>
                    <Button
                      variant="contained"
                      color="#C62828"
                      className={classes.button}
                      onClick={() => {
                        onToggleOpen();
                        setTabCount(tabCount - 1);
                      }}
                    >
                      Great!
                    </Button>
                  </Grid>
                </>
              ) : areAllItemsApproved && totalProducts === numberOfApprovedItems ? (
                <>
                  <p className={classes.title}>All Products are now approved</p>
                  <Grid style={{ justifyContent: 'center', display: 'inline-flex', paddingTop: 16 }}>
                    <Button
                      variant="contained"
                      color="#C62828"
                      className={classes.button}
                      onClick={() => {
                        onToggleOpen();
                        handlePlaceNewOrder();
                      }}
                    >
                      Place order now
                    </Button>
                  </Grid>
                </>
              ) : (
                <>
                  <p className={classes.title}>This item is now ready to be ordered</p>
                  <Grid
                    xs={12}
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      textAlign: 'center',
                      alignItems: 'center',
                      padding: '0px 8px'
                    }}
                  >
                    <WarningIcon style={{ color: '#EF6C00', fontSize: 12 }} />
                    <Typography className={classes.warningNote}>
                      {totalProducts - numberOfApprovedItems} more products in this pack are still missing your approval
                    </Typography>
                  </Grid>
                  <Grid style={{ justifyContent: 'center', display: 'inline-flex', paddingTop: 16 }}>
                    <Button
                      variant="contained"
                      color="#C62828"
                      className={classes.button}
                      onClick={() => {
                        onToggleOpen();
                        selectNextItem(true);
                      }}
                    >
                      Approve next item
                      <ArrowForwardIcon style={{ marginLeft: 8 }} />
                    </Button>
                  </Grid>
                </>
              )}
            </Grid>
          </Grid>
        );
      case 'ready-to-order':
        return (
          <Grid container direction="row" alignItems="center" justifyContent="center" style={{ padding: 0 }}>
            <Grid
              item
              xs={8}
              style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}
            >
              <img src="/images/public/mockup-approval.svg" alt="mockup-approval" className={classes.logo} />
              <p className={classes.title}>All products are now approved</p>
            </Grid>
            <Grid xs={12} style={{ justifyContent: 'center', display: 'inline-flex', paddingTop: 16 }}>
              <Button
                variant="contained"
                color="#C62828"
                className={classes.button}
                onClick={() => {
                  onToggleOpen();
                  setTabCount(tabCount - 1);
                }}
              >
                Place order now
              </Button>
            </Grid>
          </Grid>
        );
      case 'delete':
        return (
          <Grid container direction="row" alignItems="center" justifyContent="center" style={{ padding: 0 }}>
            <Grid item>
              <p className={classes.title}>Are you sure you want to delete this decoration?</p>
            </Grid>
            <Grid xs={12}>
              <div style={{ width: '50%', margin: 'auto' }}>
                <p className={classes.subtitle}>
                  If you change your mind, you will have to add these decoration details again.
                </p>
              </div>
            </Grid>
            <Grid item xs={4} style={{ textAlign: 'center' }}>
              <Button variant="text" onClick={onToggleOpen} className={classes.cancelButton}>
                Cancel
              </Button>
            </Grid>
            <Grid item xs={4} justifyContent="center">
              <Button
                variant="contained"
                color="#C62828"
                className={classes.deleteButton}
                onClick={() => {
                  requestDelete();
                  onToggleOpen();
                }}
              >
                Delete decoration
              </Button>
            </Grid>
          </Grid>
        );
    }
  };

  return (
    <Modal
      open={open}
      onClose={onToggleOpen}
      innerClasses={type === 'design-request' ? { paper: classes.dialog } : { paper: classes.commonDialog }}
    >
      {renderContent()}
    </Modal>
  );
};

export default ProofDetailsModal;
