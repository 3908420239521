/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import { Box, Grid, makeStyles, Typography, Snackbar, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { Tooltip, Typography as SwagupTypography, Button } from '@swagup-com/components';
import { useDropzone } from 'react-dropzone';
import { Animated } from 'react-animated-css';
import isEmpty from 'lodash/isEmpty';
import Confetti from 'react-canvas-confetti';
import { useFlags } from 'launchdarkly-react-client-sdk';
import gtm from '../../../utils/gtm';
import { DecorationFieldEdit, ImageDialog, ZoomIconSearch } from './ProofDetailsSectionsExtension';
import CustomizedSelect from './CustomizedSelect';
import { hasExtension, formatBytes } from './common';
import styles from './styles/itemsDecorationPanel';
import { PRODUCT_STATUS_OPTIONS } from '../../../utils/constants';
import { StylessButton } from '../../buttons';
import { productStatus } from '../../../apis/constants';

const useStyles = makeStyles(styles);

const hasArtwork = decoration => decoration.Artwork1 && decoration.Artwork1_S3_Location;

const ActionSection = ({
  children,
  onAccept,
  onDecline,
  acceptText,
  declineText,
  declineVariant,
  oneButton,
  join,
  disabled
}) => {
  const classes = useStyles();
  return (
    <Grid container justifyContent="flex-end" alignItems="flex-end" className={classes.continueSection}>
      {!oneButton && (
        <Grid item>
          <Button
            variant={declineVariant}
            size="small"
            onClick={() => {
              onDecline();
              gtm.onClickContinue('Delete decoration clicked');
            }}
            className={classes.wizardButtons}
            disabled={disabled}
          >
            {declineText}
          </Button>
        </Grid>
      )}
      {!join && <Grid item xs />}
      <Grid item>
        {children || (
          <Button variant="primary" size="small" className={classes.wizardButtons} onClick={onAccept}>
            {acceptText}
          </Button>
        )}
      </Grid>
    </Grid>
  );
};

const FileUploadZone = ({ handleFileUpload, children, disabled, renderComponent, decoration }) => {
  const classes = useStyles();
  const [error, setError] = useState(null);
  const [open, setOpen] = useState(false);

  const validTypes = [
    'image/jpeg',
    'image/png',
    'image/svg+xml',
    'application/pdf',
    'application/eps',
    'application/illustrator',
    'application/x-photoshop'
  ];

  const validExtensions = ['.jpg', '.jpeg', '.png', '.svg', '.eps', '.pdf', '.psd', '.ai'];
  const MAX_FILE_SIZE = 20 * 1024 * 1024;

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    disabled,
    onDrop: acceptedFiles => {
      const invalidFiles = acceptedFiles.filter(file => {
        const isValidType =
          validTypes.includes(file.type) || validExtensions.some(ext => file?.name.toLowerCase().endsWith(ext));
        return !isValidType;
      });
      const oversizedFiles = acceptedFiles.filter(file => file?.size > MAX_FILE_SIZE);

      if (invalidFiles.length > 0) {
        setError('This is an Invalid File Type');
        setOpen(true);
        return;
      }

      if (oversizedFiles?.length > 0) {
        setError('File size exceeds 20 MB. Please upload a smaller file.');
        setOpen(true);
        return;
      }

      const validFiles = acceptedFiles.filter(file => {
        const isValidType =
          validTypes.includes(file?.type) || validExtensions.some(ext => file?.name.toLowerCase().endsWith(ext));
        return isValidType && file?.size <= MAX_FILE_SIZE;
      });
      handleFileUpload(validFiles);
      setError(null);
    },
    accept:
      'image/jpeg, image/png, image/svg+xml, application/pdf, application/eps, application/illustrator, application/x-photoshop'
  });

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  React.useEffect(() => {
    let timer;
    if (open) {
      timer = setTimeout(() => {
        setOpen(false);
      }, 5000);
    }
    return () => clearTimeout(timer);
  }, [open]);

  return (
    <>
      <Grid
        container
        {...getRootProps()}
        style={{
          cursor: disabled ? 'not-allowed' : 'pointer',
          width: 'auto'
        }}
      >
        <input {...getInputProps()} disabled={disabled} />
        {renderComponent ? renderComponent({ isDragActive }) : children}
      </Grid>

      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        open={open}
        onClose={handleClose}
        message={error}
        autoHideDuration={null}
        action={
          <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        }
        className={classes.snackbar}
      />
    </>
  );
};

const ArtworkDisplay = ({ decoration, isDecoration }) => {
  const { mockUpApproval } = useFlags();
  const classes = useStyles();
  const [openDialog, setOpenDialog] = useState(false);

  const nonImage = isDecoration
    ? hasExtension(decoration?.Artwork1, ['.pdf', '.eps', '.ai'])
    : decoration?.Artwork1_S3_Location;
  return (
    <>
      <Grid container alignItems="center">
        <Grid item xs={12}>
          <div className={classes.artworkContainer} style={isDecoration ? { border: '0.5px solid #E5E7E8' } : {}}>
            <Grid className={classes.artworkContainerFileName}>
              <Tooltip
                title={nonImage === undefined || nonImage === '' ? 'moontest.svg ' : decoration?.artwork?.fileName}
              >
                <span className={classes.decorationNameWithEllipsis}>
                  <SwagupTypography variant="body3RegularInter" className={classes.decorationNameWithEllipsis}>
                    {nonImage === undefined || nonImage === '' ? 'moontest.svg ' : decoration?.artwork?.fileName}
                  </SwagupTypography>
                </span>
              </Tooltip>
              <SwagupTypography style={{ fontFamily: 'Inter', fontSize: 14 }}>
                {nonImage === undefined || nonImage === '' ? '132MB' : decoration?.artwork?.fileSize}
              </SwagupTypography>
            </Grid>
            <Grid style={{ padding: 4 }}>
              <StylessButton onClick={() => setOpenDialog(true)} width="100%" style={{ cursor: 'zoom-in' }}>
                <div className={classes.container384x284}>
                  <img
                    src={decoration?.Artwork1_S3_Location}
                    alt="swagup"
                    className={mockUpApproval ? classes.logoV2 : classes.logo}
                  />
                  <ZoomIconSearch classes={classes} />
                </div>
              </StylessButton>
            </Grid>
          </div>
        </Grid>
      </Grid>
      <ImageDialog
        img={
          !isDecoration
            ? decoration?.Artwork1_S3_Location || '/images/public/moontest.svg'
            : '/images/public/moontest.svg'
        }
        largeImageSrc={decoration?.Artwork1_S3_Location || '/images/public/moontest.svg'}
        open={openDialog}
        onClose={() => setOpenDialog(false)}
      />
    </>
  );
};

const ItemsDecorationPanel = ({
  item,
  tabValue,
  decoration,
  updateDecoration,
  setUpdateDecoration,
  disabled,
  disableDecorations,
  readOnly,
  explicitDisableFields = []
}) => {
  const [uploadWarning, setUploadWarning] = useState(false);
  const [deleteWarning, setDeleteWarning] = useState(false);
  const classes = useStyles({ disabled, uploadWarning, deleteWarning });

  const handleFileUpload = acceptedFiles => {
    const image = acceptedFiles[0];
    const filePath = URL.createObjectURL(image);
    const size = formatBytes(image.size);
    if (image) {
      const name = image.name.replace(/\s+/g, '-');
      const searchedDecoration = updateDecoration?.map((i, index) => {
        if (index === tabValue) {
          return {
            ...i,
            artwork: { image, fileName: name, fileSize: size },
            Artwork1: name,
            Artwork1_S3_Location: filePath
          };
        }
        return { ...i };
      });
      updateDecoration[tabValue] = searchedDecoration[tabValue] || {
        artwork: { image, fileName: name, fileSize: size },
        Artwork1: name,
        Artwork1_S3_Location: filePath
      };
      setUpdateDecoration([...updateDecoration]);
    }

    setUploadWarning(!hasExtension(image.name, ['.svg', '.eps', '.ai']));
  };

  const handleTextChange = ({ target: { name, value } }) => {
    const searchedDecoration = updateDecoration.map((i, index) => {
      if (index === tabValue) {
        return { ...i, [name]: value };
      }
      return { ...i };
    });
    updateDecoration[tabValue] = searchedDecoration[tabValue] || { [name]: value };
    setUpdateDecoration([...updateDecoration]);
  };

  const nonImage = decoration?.Artwork1_S3_Location === '';
  const { mockUpApproval } = useFlags();

  return (
    <Grid container className={classes.panelBody}>
      {updateDecoration ? (
        disableDecorations ? (
          <>
            <Grid container spacing={2} className={classes.imgContainer}>
              <Grid item xs={12} md={decoration?.Artwork1_S3_Location ? 6 : 12}>
                <FileUploadZone
                  handleFileUpload={handleFileUpload}
                  disabled={disabled}
                  decoration={decoration}
                  renderComponent={props => (
                    <Grid
                      container
                      alignItems="center"
                      className={mockUpApproval ? classes.ImageUploadContainerV2 : classes.ImageUploadContainer}
                      style={{ border: props?.isDragActive ? '1px dotted #2663FF' : undefined }}
                    >
                      <Grid item>
                        <Grid justifyContent="center" className={classes.iconWrapper}>
                          <img src="/images/public/file_upload_new.svg" alt="upload" className={classes.icon} />
                        </Grid>
                        <SwagupTypography
                          variant="body3MediumInter"
                          className={mockUpApproval ? classes.uploadTextV2 : classes.uploadText}
                        >
                          Drag & Drop to upload <span>or </span>
                        </SwagupTypography>
                        <SwagupTypography
                          className={mockUpApproval ? classes.linkV2 : classes.link}
                          style={{ color: disabled ? '#787b80' : undefined }}
                        >
                          <span style={{ color: '#2663FF' }}>Choose a file</span>
                        </SwagupTypography>
                        <SwagupTypography
                          variant="body4RegularInter"
                          className={mockUpApproval ? classes.fileFormatsV2 : classes.fileFormats}
                        >
                          Accepted file types:{' '}
                          <span style={{ display: 'block', width: 'auto', marginTop: 5 }}>
                            JPEG, PNG, SVG, EPS, PDF, ADOBE AI, ADOBE PS
                          </span>
                        </SwagupTypography>
                      </Grid>
                    </Grid>
                  )}
                />
              </Grid>
              {decoration?.Artwork1_S3_Location && (
                <Grid item xs={12} md={6} className={classes.imgPreview}>
                  <ArtworkDisplay decoration={updateDecoration[tabValue]} isDecoration={false} />
                </Grid>
              )}
            </Grid>
            <Grid xs={12}>
              <div className={classes.notesWrapper}>
                <DecorationFieldEdit
                  multiline
                  decoration={updateDecoration[tabValue]}
                  name="Notes"
                  placeholder="Notes"
                  label="Additional Notes"
                  onChange={handleTextChange}
                  editable
                  compactStyle
                  maxLength={500}
                />
              </div>
            </Grid>
          </>
        ) : (
          <Grid item>
            <>
              <Grid container alignItems="center" style={{ height: '100%' }}>
                {!nonImage || productStatus.mockupReview === item.new_status ? (
                  <Grid container>
                    <p className={classes.proofSectionLabel} style={{ marginBottom: 12 }}>
                      {readOnly ? 'Proof File' : 'Upload Artwork'}
                    </p>
                    {!readOnly ? (
                      <Grid container spacing={2} className={classes.imgContainer}>
                        <Grid item xs={12} md={decoration?.Artwork1_S3_Location ? 6 : 12}>
                          <FileUploadZone
                            handleFileUpload={handleFileUpload}
                            disabled={disabled}
                            decoration={decoration}
                            renderComponent={props => (
                              <Grid
                                container
                                alignItems="center"
                                className={
                                  mockUpApproval ? classes.ImageUploadContainerV2 : classes.ImageUploadContainer
                                }
                                style={{ border: props?.isDragActive ? '1px dotted #2663FF' : undefined }}
                              >
                                <Grid item>
                                  <Grid justifyContent="center" className={classes.iconWrapper}>
                                    <img
                                      src="/images/public/file_upload_new.svg"
                                      alt="upload"
                                      className={classes.icon}
                                    />
                                  </Grid>
                                  <SwagupTypography
                                    variant="body3MediumInter"
                                    className={mockUpApproval ? classes.uploadTextV2 : classes.uploadText}
                                  >
                                    Drag & Drop to upload <span>or </span>
                                  </SwagupTypography>
                                  <SwagupTypography
                                    className={mockUpApproval ? classes.linkV2 : classes.link}
                                    style={{ color: disabled ? '#787b80' : undefined }}
                                  >
                                    <span style={{ color: '#2663FF' }}>Choose a file</span>
                                  </SwagupTypography>
                                  <SwagupTypography
                                    variant="body4RegularInter"
                                    className={mockUpApproval ? classes.fileFormatsV2 : classes.fileFormats}
                                  >
                                    Accepted file types:{' '}
                                    <span style={{ display: 'block', width: 'auto', marginTop: 5 }}>
                                      JPEG, PNG, SVG, EPS, PDF, ADOBE AI, ADOBE PS
                                    </span>
                                  </SwagupTypography>
                                </Grid>
                              </Grid>
                            )}
                          />
                        </Grid>
                        {decoration?.Artwork1_S3_Location && (
                          <Grid item xs={12} md={6} className={classes.imgPreview}>
                            <ArtworkDisplay decoration={updateDecoration[tabValue]} isDecoration={false} />
                          </Grid>
                        )}
                      </Grid>
                    ) : (
                      <Grid
                        container
                        alignItems="center"
                        className={mockUpApproval ? classes.ImageUploadContainerV2 : classes.ImageUploadContainer}
                        // style={{ border: props?.isDragActive ? '1px dotted #3577d4' : undefined }}
                      >
                        <Grid item xs={12} md={6} className={classes.imgPreview}>
                          <ArtworkDisplay decoration={updateDecoration[tabValue]} isDecoration={false} />
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                ) : null}
                {!isEmpty(item?.available_imprint_options) && (
                  <Grid style={{ marginTop: 20 }}>
                    <p className={classes.proofSectionLabel}>Decoration method</p>
                    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                      {item?.available_imprint_options.map(i => {
                        const color = i === updateDecoration[tabValue]?.Imprint_Type ? '#2663FF' : '#D6D8DB';
                        return (
                          <Box
                            height={50}
                            width="content-fit"
                            marginRight={4}
                            borderRadius={5}
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            gap={4}
                            sx={
                              !readOnly
                                ? {
                                    border: `1px solid ${color}`,
                                    cursor: 'pointer',
                                    padding: '12px 12px',
                                    margin: '8px 0px'
                                  }
                                : {
                                    border: `1px solid ${color}`,
                                    padding: '12px 12px',
                                    margin: '8px 0px'
                                  }
                            }
                            onClick={() => {
                              const searchedDecoration = updateDecoration?.map((item, index) => {
                                if (index === tabValue) {
                                  return {
                                    ...item,
                                    Imprint_Type: i
                                  };
                                }
                                return { ...item };
                              });
                              updateDecoration[tabValue] = searchedDecoration[tabValue] || { Imprint_Type: i };
                              if (!readOnly) setUpdateDecoration([...updateDecoration]);
                            }}
                          >
                            <Typography style={{ fontSize: 14, color: '#4A4F54', padding: 12 }}>{i}</Typography>
                          </Box>
                        );
                      })}
                    </div>
                  </Grid>
                )}
                <Grid xs={12} style={{ marginTop: 10 }}>
                  <DecorationFieldEdit
                    decoration={updateDecoration[tabValue]}
                    name="Location"
                    placeholder="Type here"
                    label="Decoration Location"
                    onChange={handleTextChange}
                    editable
                    compactStyle
                    maxLength={100}
                    disabled={readOnly}
                  />
                </Grid>
                <Grid xs={12} style={{ marginTop: 10 }}>
                  <DecorationFieldEdit
                    decoration={updateDecoration[tabValue]}
                    name="Dimensions"
                    placeholder="Type here"
                    label="Decoration Dimension"
                    onChange={handleTextChange}
                    editable
                    compactStyle
                    maxLength={100}
                    disabled={readOnly}
                  />
                </Grid>
                <Grid xs={12} style={{ marginTop: 10 }}>
                  <DecorationFieldEdit
                    decoration={updateDecoration[tabValue]}
                    name="Colors"
                    placeholder="Type here"
                    label="Decoration Color"
                    onChange={handleTextChange}
                    editable
                    compactStyle
                    maxLength={100}
                    disabled={explicitDisableFields.includes('colors') || readOnly}
                  />
                </Grid>
                <Grid xs={12}>
                  <div className={classes.notesWrapper}>
                    <DecorationFieldEdit
                      multiline
                      decoration={updateDecoration[tabValue]}
                      name="Notes"
                      placeholder="Notes"
                      label="Additional Notes"
                      onChange={handleTextChange}
                      editable
                      compactStyle
                      maxLength={500}
                      disabled={readOnly}
                    />
                  </div>
                </Grid>
              </Grid>
            </>
          </Grid>
        )
      ) : null}
    </Grid>
  );
};

export default ItemsDecorationPanel;
