import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { AppBar, Box, Grid, makeStyles, Tabs, Tab, Paper, Typography } from '@material-ui/core';
import { Link, useHistory } from 'react-router-dom';
import { Button } from '@swagup-com/components';
import isEmpty from 'lodash/isEmpty';
import isArray from 'lodash/isArray';
import isPlainObject from 'lodash/isPlainObject';
import isEqual from 'lodash/isEqual';
import findLast from 'lodash/findLast';
import AddCircleOutlinedIcon from '@material-ui/icons/AddCircleOutlined';
import CancelIcon from '@material-ui/icons/Cancel';
import styles from './styles/FeedbackSection';
import ItemsDecorationPanel from './ItemsDecorationPanel';
import { decorationName, formatColor, hasExtension } from './common';
import { productStatus } from '../../../apis/constants';
import ProofDetailsModal from './ProofDetailsModal';

const useStyles = makeStyles(styles);

// Given two objects (a proof item) an a copy, it returns a object with the properties that are different
// it also preform some other taks like adding the API name (which not changes) for the case of decorations
const getDifferencesBetweenTwoObjects = (obj1, obj2 = {}, property) => {
  let diff = {};

  if (isArray(obj1)) {
    for (let i = 0; i < obj1.length; i++) {
      const deepUpdates = getDifferencesBetweenTwoObjects(obj1[i], obj2 ? obj2[i] : {}, property);
      diff = isEmpty(deepUpdates)
        ? diff
        : {
            ...diff,
            [`${property}_${i + 1}`]: { ...deepUpdates, Name: obj2[i]?.Name || obj1[i]?.Name } // propertyName_# = (differences, Name from API)
          };
    }
    return diff;
  }

  if (isPlainObject(obj1)) {
    diff = Object.keys(obj1).reduce((result, key) => {
      if (!obj2.hasOwnProperty(key)) {
        result.push(key);
      } else if (isEqual(obj1[key], obj2[key])) {
        const resultKeyIndex = result.indexOf(key);
        result.splice(resultKeyIndex, 1);
      }
      return result;
    }, Object.keys(obj2));
    let rslt = {};
    diff
      .filter(k => k !== 'created' && k !== 'updated' && k !== 'deleted') // these properties are left out the comparison
      .forEach(key => {
        const deepUpdates = getDifferencesBetweenTwoObjects(obj1[key], obj2[key], key);
        rslt =
          isEmpty(deepUpdates) && isPlainObject(obj1[key])
            ? rslt
            : {
                ...rslt,
                [key]: deepUpdates
              };
      });
    return isEmpty(rslt) && !obj1.deleted
      ? rslt
      : {
          ...rslt,
          ...diff
            .filter(k => k === 'created' || k === 'updated' || k === 'deleted') // these properties values are back included ...
            .reduce((s, p) => ({ ...s, [p]: obj1[p] }), {}) // ....................when a differences are found
        };
  }
  return isEqual(obj1, obj2) ? undefined : obj1;
};

const markItemsDecorations = item => ({
  ...item,
  decorations: item.decorations ? item.decorations.map((d, idx) => ({ ...d, id: idx })) : []
});

const parseChanges = (changes, item) => {
  const apiModel = {
    ...item,
    itemColor: { theme_color_hex: formatColor(item.theme_color_hex), theme_color_name: item.theme_color }
  };
  return { target: changes, source: apiModel };
};

const generateItemChangesRequest = (changes, item) => {
  const { target, source } = parseChanges(changes, item);
  return getDifferencesBetweenTwoObjects(target, source);
};

const getColorHexValue = value => (value ? `#${value?.replace('#', '')}` : undefined);

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const FeedbackSection = ({
  item,
  tabCount,
  setTabCount,
  tabValue,
  setTabValue,
  onChangesRequested,
  changesHistory,
  setModalType,
  modalType,
  setOpenProofDetailsModal,
  openProofDetailsModal,
  updateDecoration,
  setUpdateDecoration,
  setDeletingDecorationId,
  status
}) => {
  const classes = useStyles();
  const history = useHistory();
  const isEditable = [productStatus.mockupReview, productStatus.proofReview].includes(status);
  const [explicitDisableFields, setExplicitDisableFields] = useState([]);

  const [changes, setChanges] = useState({});

  const handleChangeTab = (event, newValue) => {
    setTabValue(newValue);
  };

  const query = new URLSearchParams(history.location.search);
  const openParam = query.get('open');
  const localDecorations = changes.decorations?.filter(d => !d.deleted);
  const [exsistingDecorationsSize, setExsistingDecorationsSize] = React.useState(0);
  const disableDecorationsChanges = productStatus.mockupReview !== item.new_status;
  const disableSendNotes = ![productStatus.mockupReview, productStatus.proofReview].includes(item.new_status);
  const disableDecorations = productStatus.mockupDesign === item.new_status;
  const isColorSectionDisabled =
    !item.available_colors || item.available_colors.length === 0 || disableDecorations || disableDecorationsChanges;

  useEffect(() => {
    (async () => {
      if (item?.decorations) {
        const data = await item?.decorations;
        setExsistingDecorationsSize(data?.length);
        setUpdateDecoration([...data]);
        if (data.length === 0) setTabCount(1);
      }
    })();
  }, [item]);

  const getPanelContent = readOnly => {
    return (
      <ItemsDecorationPanel
        item={item}
        tabValue={tabValue}
        decoration={(item?.decorations && item?.decorations[tabValue]) || {}}
        getDecorationName={decorationName}
        lastOne={localDecorations?.length === 1}
        disabled={disableDecorationsChanges}
        disableSendNotes={disableSendNotes}
        disableDecorations={disableDecorations}
        updateDecoration={updateDecoration}
        setUpdateDecoration={setUpdateDecoration}
        readOnly={!isEditable && readOnly}
        explicitDisableFields={explicitDisableFields}
      />
    );
  };

  useEffect(() => {
    const isEmptyObject = updateDecoration[tabValue] && !Object.values(updateDecoration[tabValue]).some(x => x !== '');
    if (isEmptyObject) {
      const data = [];
      updateDecoration?.map((i, index) => {
        if (index !== tabValue && i != undefined) {
          data.push(i);
        }
      });
      setUpdateDecoration([...data]);
    }
  }, [updateDecoration]);

  const deleteSingleDecoration = id => {
    const data = [];
    updateDecoration?.map((i, index) => {
      if (index !== id) {
        data.push(i);
      }
    });
    setUpdateDecoration([...data]);
  };

  const totalDecorationCount = useMemo(() => {
    return item.decorations.length + tabCount;
  }, [tabCount, item.decorations]);

  return (
    <Box height="100%">
      <Grid container>
        <Box className={classes.ctaWrapper}>
          <Grid item xs={12}>
            <AppBar position="static" color="default" className={classes.appBarStyles}>
              <div style={{ display: 'inline-flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <div>
                  <Tabs
                    className={classes.tabs}
                    TabIndicatorProps={{
                      style: { display: 'none' }
                    }}
                    value={tabValue}
                    variant="scrollable"
                    onChange={handleChangeTab}
                    scrollButtons="off"
                    indicatorColor="primary"
                    textColor="undefined"
                  >
                    {item.decorations &&
                      !disableDecorations &&
                      item.decorations.map((item, ind) => {
                        if (item.Name) {
                          return (
                            <Tab
                              key={ind}
                              className={tabValue === ind ? classes.activeTab : classes.tab}
                              label={
                                <div
                                  style={{
                                    display: 'inline-flex',
                                    alignItems: 'center',
                                    minWidth: 'max-content',
                                    padding: 4
                                  }}
                                >
                                  <Typography style={tabValue === ind ? { color: '#ffff' } : { color: '#131415' }}>
                                    {item.Name}
                                  </Typography>
                                  {totalDecorationCount > 1 && (
                                    <Button
                                      variant="text"
                                      className={classes.deleteTabBtn}
                                      onClick={() => {
                                        setDeletingDecorationId(item?.Name);
                                        setModalType('delete');
                                        setOpenProofDetailsModal(true);
                                      }}
                                      disabled={status !== productStatus?.mockupReview}
                                    >
                                      <CancelIcon
                                        style={
                                          tabValue === ind
                                            ? { marginLeft: '8px', color: '#ffff' }
                                            : { marginLeft: '8px' }
                                        }
                                      />
                                    </Button>
                                  )}
                                </div>
                              }
                            />
                          );
                        }
                      })}
                    {disableDecorations ? (
                      <Tab
                        className={classes.disableDecorationsTab}
                        label={
                          <div style={{ display: 'inline-flex', alignItems: 'center', minWidth: '140px', padding: 4 }}>
                            <Typography style={{ color: '#131415' }}>Uploaded artwork</Typography>
                          </div>
                        }
                      />
                    ) : (
                      new Array(tabCount).fill('').map((itm, ind) => (
                        <Tab
                          key={ind}
                          className={tabValue === ind + 1 ? classes.activeTab : classes.tab}
                          label={
                            <div
                              style={{ display: 'inline-flex', alignItems: 'center', minWidth: '140px', padding: 4 }}
                            >
                              <Typography style={tabValue === ind + 1 ? { color: '#ffff' } : { color: '#131415' }}>
                                Decoration {ind + 1}
                              </Typography>
                              {totalDecorationCount > 1 && (
                                <Button
                                  variant="text"
                                  className={classes.deleteTabBtn}
                                  disabled={ind === item.decorations - 1 + tabValue}
                                  onClick={e => {
                                    e.stopPropagation();
                                    setTabCount(tabCount - 1);
                                    setTabValue(tabCount - 1);
                                    deleteSingleDecoration(tabValue);
                                  }}
                                >
                                  <CancelIcon
                                    style={
                                      tabValue === ind + 1
                                        ? { marginLeft: '8px', color: '#ffff' }
                                        : { marginLeft: '8px' }
                                    }
                                  />
                                </Button>
                              )}
                            </div>
                          }
                        />
                      ))
                    )}
                  </Tabs>
                </div>

                <div>
                  <Button
                    variant="text"
                    onClick={() => {
                      setTabCount(tabCount + 1);
                      setTabValue(tabCount + 1);
                    }}
                    disabled={disableDecorations || disableDecorationsChanges}
                    className={classes.cta}
                  >
                    Add{' '}
                    <AddCircleOutlinedIcon
                      style={
                        disableDecorations || disableDecorationsChanges
                          ? { marginLeft: 8, color: '#bdbdbd' }
                          : { marginLeft: 8, color: '#125CFF' }
                      }
                    />
                  </Button>
                </div>
              </div>
              {new Array(exsistingDecorationsSize + tabCount).fill('').map((_item, ind) => {
                const isNewDecoration = ind < exsistingDecorationsSize;
                return (
                  <TabPanel key={_item} value={tabValue} index={ind} className={classes.tabContainerStyles}>
                    {getPanelContent(isNewDecoration)}
                  </TabPanel>
                );
              })}
            </AppBar>
          </Grid>
        </Box>
      </Grid>
      <ProofDetailsModal
        open={openProofDetailsModal}
        onToggleOpen={() => setOpenProofDetailsModal(false)}
        setTabCount={setTabCount}
        tabCount={tabCount}
        type={modalType}
      />
    </Box>
  );
};

export default FeedbackSection;
