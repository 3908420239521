import * as React from 'react';
import { makeStyles, Grid, Divider } from '@material-ui/core';
import { Redirect, useParams, useHistory, useRouteMatch } from 'react-router-dom';
import { useQuery, useQueryClient, useQueries } from 'react-query';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useErrorHandler } from 'react-error-boundary';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import debounce from 'lodash/debounce';

import { HeaderSection, ProductCardSection, ProductDetail } from './ProofDetailsSections';
import { Helmet, CenteredGrid } from '../../shared';
import tags from '../../../apis/seoTags';
import Loader from '../../global/Loader';
import { useQueryParams, useSizes } from '../../../hooks';
import styles from './styles/ProofDetails';
import apiPaths from '../../../helpers/apiPaths';
import proofsApi from '../../../apis/swagup/proofs';
import orderApi from '../../../apis/swagup/order';
import accountProductsApi from '../../../apis/swagup/accountProducts';
import { isPack, sumByQuantity, isOneSize } from '../../../helpers/utils';
import { cancellableQuery } from '../../pages/orders/requested/common/utilsOrder';
import useDiscountPricing from '../../../hooks/useDiscountPricing';

const useStyles = makeStyles(styles);

const emptyProof = {
  id: 0,
  opportunity: 0,
  product: {
    record_type: 'product',
    items: []
  }
};

const mapAccountProductToProof = ap => ({
  id: ap.id,
  opportunity: 0,
  product: {
    ...ap,
    price: +ap.price_per_unit_based_on_100
  },
  rush_production: ap.rush_production,
  sizes: ap.stock?.map(s => ({
    ...s,
    price: 0,
    rush_fee: 0
  })),
  is_in_invoice: ap.is_in_invoice,
  price: +ap.price_per_unit_based_on_100
});

const ProofDetails = () => {
  const classes = useStyles();

  const [isLoading, setIsLoading] = React.useState(false);
  const [nextItem, setNextItem] = React.useState(null);
  const [selectedProduct, setSelectedProduct] = React.useState(null);
  const [canFetchPrices, setCanFetchPrices] = React.useState(false);
  const [localProof, setLocalProof] = React.useState(emptyProof);
  const [isProductColorChange, setIsProductColorChange] = React.useState(false);
  const [doRemoveItem, toggleDoRemoveItem] = React.useReducer(prev => !prev, false);

  const queryClient = useQueryClient();
  const proofOrApId = +useParams().id;
  const handleError = useErrorHandler();

  const query = useQueryParams();
  const history = useHistory();

  const { showDesignTabFeTemp102521: showProductsTab } = useFlags();
  const { path } = useRouteMatch();

  const productId = +query.get('product');

  const inProductDetails = showProductsTab && path === '/product-details/:id';

  const { data: fetchedProduct = { id: 0 }, isLoading: isLoadingProduct, refetch } = useQuery(
    [apiPaths.accountProducts, { ids: proofOrApId }],
    () => accountProductsApi.fetch({ ids: proofOrApId }),
    {
      enabled: inProductDetails,
      retry: false,
      useErrorBoundary: false,
      select: data => ({
        ...data.results[0],
        items: data.results[0].items?.map(i => ({
          ...i,
          product: { ...i.product, price: +i.price_per_unit_based_on_100 }
        }))
      }),
      onSuccess: data => {
        if (isEmpty(data)) {
          history.push('/products');
        } else {
          const proof = mapAccountProductToProof(data);
          setLocalProof(proof);
          queryClient.setQueryData([apiPaths.proofs, proofOrApId], proof);
        }
      },
      onError: () => history.push('/products')
    }
  );
  const {
    data: proof = inProductDetails ? mapAccountProductToProof(fetchedProduct) : emptyProof,
    isLoading: isLoadingProof,
    isFetching,
    isFetchedAfterMount
  } = useQuery([apiPaths.proofs, proofOrApId], () => proofsApi.fetchById(proofOrApId), {
    enabled: !inProductDetails,
    onSuccess: data => {
      if (!isFetchedAfterMount) setLocalProof(data);
    },
    onError: error => {
      if (error.status === 404)
        history.replace('/orders-requested', { message: 'The product or the order is no longer active' });
      handleError(error.data);
    }
  });

  React.useEffect(() => {
    if (inProductDetails && !isLoadingProduct && isEqual(emptyProof, localProof)) {
      setLocalProof(proof);
      queryClient.setQueryData([apiPaths.proofs, proofOrApId], proof);
    }
  }, [inProductDetails, isLoadingProduct, localProof, proof, proofOrApId, queryClient]);

  const { data: restProducts = [], isLoading: isLoadingOpp, isFetched } = useQuery(
    [apiPaths.opportunities, proof.opportunity],
    () => orderApi.fetchOpportunity(proof.opportunity),
    {
      enabled: Boolean(proof?.opportunity && isPack(proof.product.record_type)),
      select: data => data.products.filter(p => p.id !== proof.id),
      onError: error => handleError(error.data)
    }
  );

  const { getDiscountPrice } = useDiscountPricing();

  const selectPriceInfo = priceInfo => ({
    ...proof,
    price: getDiscountPrice(proof.price),
    product: {
      ...proof.product,
      price: getDiscountPrice(proof.product.price),
      items: isPack(proof.product.record_type)
        ? proof.product.items.map(item => ({
            ...item,
            product: {
              ...item.product,
              price: getDiscountPrice(
                +priceInfo.items?.find(i => i.product === item.product.id)?.price ||
                  +priceInfo.product.items?.find(i => i.product.id === item.product.id)?.product.price
              )
            }
          }))
        : proof.product.items
    },
    sizes: proof.sizes?.map(size => ({
      ...size,
      price: getDiscountPrice(priceInfo.price)
    }))
  });

  const { data: proofWithPrices = selectPriceInfo(proof) } = useQuery(
    [apiPaths.proofPrices, [proof, ...restProducts]],
    () => {
      queryClient.cancelQueries(apiPaths.proofPrices);
      return cancellableQuery(proofsApi.fetchPrices)([proof, ...restProducts]);
    },
    {
      enabled: Boolean(proof?.opportunity && isFetched && canFetchPrices && !inProductDetails),
      retry: false,
      useErrorBoundary: false,
      select: selectPriceInfo,
      onSettled: () => {
        setIsLoading(false);
        setCanFetchPrices(false);
      }
    }
  );

  const setCommentsQueryConfig = id => ({
    queryKey: [apiPaths.accountProducts, 'comments', +id],
    queryFn: () => accountProductsApi.fetchComments(id),
    enabled: !isLoadingProof && localProof.id !== 0 && !isLoadingProduct
  });

  const queries = isPack(localProof.product.record_type)
    ? localProof.product.items.map(i => setCommentsQueryConfig(i.product.id))
    : [setCommentsQueryConfig(localProof.product.id)];
  const commentsQueries = useQueries(queries);

  const navigateToProduct = React.useCallback(
    id => {
      if (id) {
        if (proofOrApId === id) {
          // bulk in product-details
          query.delete('product');
        } else {
          query.set('product', id);
        }
        history.replace({ ...history.location, search: query.toString() });
      }

      const item = isPack(proof.product.record_type)
        ? proof.product.items.find(i => i.product.id === id)
        : { units_per_pack: 1, product: proof.product };

      if (!isEqual(item, selectedProduct)) setSelectedProduct(item);
      setNextItem(prev => (!isEqual(item, prev) ? item : prev));
    },
    [history, proof.product, proofOrApId, query, selectedProduct]
  );

  React.useEffect(() => {
    if (isFetching) return;

    if (isPack(proof.product.record_type)) {
      const packItem = proof.product.items.find(i => i.product.id === productId);

      if (packItem) {
        setSelectedProduct(packItem);
        setNextItem(packItem);
      } else {
        navigateToProduct(proof.product.items[0]?.product.id);
      }
    } else {
      navigateToProduct(proof.product.id);
    }
  }, [isFetching, navigateToProduct, proof, productId]);

  const { data: size } = useSizes({ select: data => data.find(s => s.name === 'One Size') });

  const debouncedFetchPrices = React.useCallback(debounce(setCanFetchPrices, 750), []);

  React.useEffect(() => {
    const getApparelProductCount = (items, prodId) =>
      items.filter(i => i.product.is_apparel && i.product.id !== prodId).length;
    const turnToOneSize = sizes => {
      return [
        {
          size,
          quantity: sumByQuantity(sizes)
          // price: not used
          // rush_fee: not used
        }
      ];
    };

    if (doRemoveItem && nextItem) {
      queryClient.setQueryData([apiPaths.proofs, proofOrApId], currentProof => ({
        ...currentProof,
        product: {
          ...currentProof.product,
          items: currentProof.product.items.filter(i => i.product.id !== nextItem.product.id)
        },
        sizes:
          isOneSize(currentProof.sizes) || getApparelProductCount(currentProof.product.items, nextItem.product.id) > 0
            ? currentProof.sizes
            : turnToOneSize(currentProof.sizes)
      }));

      if (!inProductDetails && proof.product.items.length > 1) {
        setIsLoading(true);
        debouncedFetchPrices(true);
      }
      toggleDoRemoveItem();
      setNextItem(selectedProduct);
    }
  }, [
    doRemoveItem,
    nextItem,
    proof,
    proofOrApId,
    queryClient,
    selectedProduct,
    size,
    debouncedFetchPrices,
    inProductDetails
  ]);

  if (!showProductsTab && path === '/product-details/:id') return <Redirect to="/" />;

  const getSelectedProductComments = () =>
    queryClient.getQueryData([apiPaths.accountProducts, 'comments', +selectedProduct.product.id]);

  const loading =
    isLoadingProof ||
    isLoadingOpp ||
    isLoadingProduct ||
    commentsQueries.some(q => q.status === 'loading') ||
    (selectedProduct && !getSelectedProductComments());

  if (loading) return <Loader />;

  const fromProducts = history.location.state?.fromProducts;

  const { leftBarNavigation } = useFlags();

  return (
    <>
      <Helmet tags={tags.proofDetails} />
      <Grid container justifyContent="center" className={classes.container}>
        <CenteredGrid container className={classes.center}>
          <HeaderSection
            proof={proofWithPrices}
            fromProducts={fromProducts}
            inProductDetails={inProductDetails}
            classes={classes}
          />
          <Divider className={classes.divider} />
          <Grid container spacing={3} style={{ flexWrap: 'nowrap' }}>
            <Grid item style={{ paddingTop: 0 }} className={leftBarNavigation ? classes.leftSection : ''}>
              <ProductCardSection
                onDeleteProduct={toggleDoRemoveItem}
                onSelectProduct={setNextItem}
                setLocalProof={setLocalProof}
                proof={proofWithPrices}
                localProof={localProof}
                selected={selectedProduct?.product}
                packOrBulkItem={selectedProduct}
                isLoadingPrices={isLoading}
                inProductDetails={inProductDetails}
                onChangePrice={setIsLoading}
                onModifyQuantities={setCanFetchPrices}
                isProductColorChange={isProductColorChange}
                setIsProductColorChange={setIsProductColorChange}
              />
            </Grid>
            <Grid item xs className={classes.productDetails}>
              {selectedProduct && (
                <ProductDetail
                  onChangePrice={setIsLoading}
                  onModifyQuantities={setCanFetchPrices}
                  onSelectProduct={navigateToProduct}
                  setLocalProof={setLocalProof}
                  setNextItem={setNextItem}
                  proof={proofWithPrices}
                  localProof={localProof}
                  selected={selectedProduct?.product}
                  packOrBulkItem={selectedProduct}
                  nextItem={nextItem}
                  changesHistory={getSelectedProductComments()}
                  isRemoveItem={doRemoveItem}
                  inProductDetails={inProductDetails}
                  refetch={refetch}
                  isProductColorChange={isProductColorChange}
                  setIsProductColorChange={setIsProductColorChange}
                />
              )}
            </Grid>
          </Grid>
        </CenteredGrid>
      </Grid>
    </>
  );
};

export default ProofDetails;
